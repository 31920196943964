import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

declare global {
  interface Window {
    FB: any; // This assumes FB is available in the global scope
  }
}
const Footer = () => {
  useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, []);
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#a3b5c0',
        paddingTop: 5,
        boxShadow: '0 20px 10px -20px rgba(0,0,0,0.45) inset',
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={3} alignSelf={'center'}>
            <Link href="/">
              <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" className="logo-footer" />
            </Link>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" color="white" textAlign={'center'}>
              Contact
            </Typography>
            <br />
            <Typography variant="body2" color="white">
              <Link
                href="https://www.google.com/maps/place/Duna+Trade+Import-Export/@46.405372,25.7856483,17z/data=!3m1!4b1!4m6!3m5!1s0x474ad65710a20019:0xff689cca7f53cdb0!8m2!3d46.405372!4d25.7882232!16s%2Fg%2F1hc30tv44?entry=ttu"
                target="_blank"
                rel="noreferrer"
                underline="none"
                color="inherit"
              >
                <LocationOnOutlinedIcon /> &nbsp; Str. Principala, Nr. 1/B, Ciceu, Jud. Harghita
              </Link>
            </Typography>
            <br />
            <Typography variant="body2" color="white">
              <SmartphoneIcon /> &nbsp; 0748 038 392
            </Typography>{' '}
            <br />
            <Typography variant="body2" color="white">
              <AlternateEmailIcon /> &nbsp; office@duna.ro
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" color="white" textAlign={'center'}>
              Informatii
            </Typography>
            <br />
            {/* <Typography variant="body2" color="white">
              <a href="/terms-and-conditions">Termeni si conditii</a>
            </Typography> */}
            <Typography variant="body2" color="white">
              <Link href="/terms-and-conditions">Termeni si conditii</Link>
            </Typography>
            <br />
            <Typography variant="body2" color="white">
              <Link href="/cookies" color="inherit">
                Politica de cookies
              </Link>
            </Typography>
            <br />
            <Typography variant="body2" color="white">
              <Link href="http://#" color="inherit">
                Politica de confidentialitate (in progress...)
              </Link>
            </Typography>
            <br />
            <Typography variant="body2" color="white">
              <Link href="http://#" color="inherit">
                GDPR (in progress...)
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" color="white" textAlign={'center'}>
              Follow us
            </Typography>
            <br />
            <div
              className="fb-page"
              data-href="https://www.facebook.com/544657572364893?ref=embed_page"
              data-tabs=""
              data-width=""
              data-height=""
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="false"
            >
              <blockquote
                cite="https://www.facebook.com/544657572364893?ref=embed_page"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/544657572364893?ref=embed_page">DUNA TRADE Imp-Exp</a>
              </blockquote>
            </div>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="#eeeeee" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://cosmincioloca.eu/">
              Duna Trade
            </Link>{' '}
            {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
