import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import KK711Y from '../assets/hq-ceramic/KK711Y.png';
import CK721X from '../assets/hq-ceramic/CK721X.png';
import kv707x from '../assets/hq-ceramic/kv707x-02.png';
import centerless from '../assets/hq-ceramic/centerless.png';
import flatsanding from '../assets/hq-ceramic/flatsanding.png';
import backstand from '../assets/hq-ceramic/backstand.png';
import longbeltsanding from '../assets/hq-ceramic/longbeltsanding.png';
import crossbeltsanding from '../assets/hq-ceramic/crossbeltsanding.png';
import roboticgrinding from '../assets/hq-ceramic/roboticgrinding.png';
import widebeltsanding from '../assets/hq-ceramic/widebeltsanding.png';

const HighQuality = () => {
  useEffect(() => {
    document.title = 'Duna Trade | High Quality';
  }, []);

  return (
    <div className="container">
      <h1 className="titles">Tehnologie High Quality</h1>
      <br />
      <p className="content">
        Gama de produse de înaltă calitate, sunt fabricate cu granule de oxid de aluminiu şi carbură de siliciu, pe
        suport vlies (material neţesut). Aceste materiale, care oferă performanţe constante şi echilibrate, constituie
        baza gamei de produse comercializate de noi. Produsele VSM Hight Quality, de o calitate excelentă, asigură
        perfomanţe de prelucrare şi anduranţă superioare, oferind astfel soluţii de şlefuire economice şi suprafeţe
        prelucrate la standarde ridicate. Pentru prelucrarea uscată a oţelului inoxidabil sau rezistent la temperaturi
        înalte recomandăm folosirea produselor nostre Top Size. Datorită unei acoperiri speciale, performanţele de
        şlefuire cresc şi scade temperatura pe suprafaţa prelucrată.
      </p>
      <hr className="style" />
      <div className="card-container">
        <Card className="card" sx={{ boxShadow: 5, backgroundColor: '#E9E1DA' }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }}>
              <h5>Produse cu oxid de aluminiu (corindon)</h5>
              <CardMedia
                sx={{ height: 400, padding: '1em 1em 0 1em', objectFit: 'contain' }}
                component="img"
                image={KK711Y}
                title="aluminumOxide"
              />

              <div className="used-for">
                <div className="used-for-container">
                  <p>
                    <b>Potrivit pentru:</b>
                  </p>
                  <img src={centerless} className="used-for-icon" alt="usedFor" />
                  <img src={flatsanding} className="used-for-icon" alt="usedFor" />
                  <img src={backstand} className="used-for-icon" alt="usedFor" />
                </div>
              </div>
              <p className="product-description">
                Gama de produse VSM oxid de aluminiu (corindon) se remarcă prin duritate şi anduranţă ridicate.
                Corindonul este alegerea universală pentru şlefuirea suprafeţelor de metal şi lemn. Produsul este
                disponibil pe suport rigid si flexibil.
              </p>
              <div className="gridContainer">
                <h4>Granulatii</h4>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">60</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">80</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">100</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">120</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">150</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">180</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">220</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">240</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">280</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">320</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">360</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">400</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">500</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">600</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">800</span>
                </div>
              </div>
            </Typography>
          </CardContent>
        </Card>
        <br />
        <Card className="card" sx={{ boxShadow: 5, backgroundColor: '#E9E1DA' }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }}>
              <h5>Produse din carbură de siliciu</h5>
              <CardMedia
                sx={{ height: 400, padding: '1em 1em 0 1em', objectFit: 'contain' }}
                component="img"
                image={CK721X}
                title="siliconeCarbide"
              />

              <div className="used-for">
                <div className="used-for-container">
                  <p>
                    <b>Potrivit pentru:</b>
                  </p>
                  <img src={centerless} className="used-for-icon" alt="usedFor" />
                  <img src={flatsanding} className="used-for-icon" alt="usedFor" />
                  <img src={crossbeltsanding} className="used-for-icon" alt="usedFor" />
                  <img src={longbeltsanding} className="used-for-icon" alt="usedFor" />
                  <img src={roboticgrinding} className="used-for-icon" alt="usedFor" />
                </div>
              </div>
              <p className="product-description">
                Produsele VSM din carbură de siliciu se remarcă prin duritate înaltă. Astfel, sunt potrivite pentru a
                prelucra sticlă, minerale, ceramică, porţelan, piatră, titaniu, lacuri, alamă, bronz şi tablă de oţel.
                Produsul este disponibil pe suport rigid.
              </p>
              <div className="gridContainer">
                <h4>Granulatii</h4>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">180</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">400</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">600</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">800</span>
                </div>
              </div>
            </Typography>
          </CardContent>
        </Card>
        <br />
        <Card className="card" sx={{ boxShadow: 5, backgroundColor: '#E9E1DA' }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }}>
              <h5>Produse Vlies</h5>
              <CardMedia
                sx={{ height: 400, padding: '1em 1em 0 1em', objectFit: 'contain' }}
                component="img"
                image={kv707x}
                title="granulatie ceramica"
              />

              <div className="used-for">
                <div className="used-for-container">
                  <p>
                    <b>Potrivit pentru:</b>
                  </p>
                  <img src={widebeltsanding} className="used-for-icon" alt="usedFor" />
                </div>
              </div>
              <p className="product-description">
                VSM Surface Conditioning Material este un material de şlefuire "tridimensional" pe suprafaţă
                vlies-nylon, la care granulele abrazive sunt aplicate direct pe suprafaţa fibrelor. Datorită
                combinaţiilor variate granule-suport, sunt potrivite pentru aproape orice suprafaţă sau operaţie de
                finisare pretenţioasă.
              </p>
              <div className="gridContainer">
                <h4>Granulatii</h4>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">100</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">180</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">240</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">400</span>
                </div>
              </div>
            </Typography>
          </CardContent>
        </Card>
        <br />
      </div>
    </div>
  );
};

export default HighQuality;
