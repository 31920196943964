import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ContactForm, { type ContactFormData } from '../../pages/ContactForm';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

const style = {
  position: 'relative' as 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: '#E9E1DA',
  boxShadow: '0px 60px 80px 0px rgba(0,0,0,0.6), 0px 45px 26px 0px rgba(0,0,0,0.14)',
  p: 3,
};

export default function ModalOffer() {
  const handleFormSubmit = (formData: ContactFormData) => {};
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <LocalOfferOutlinedIcon />
        &nbsp; Cere oferta!
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ContactForm onSubmit={handleFormSubmit} />
        </Box>
      </Modal>
    </div>
  );
}
