import '@coreui/coreui/dist/css/coreui.min.css';
import { CCarousel, CCarouselCaption, CCarouselItem, CImage } from '@coreui/react';
// import slider1 from '../../assets/sliders/slider1.jpg';
// import slider2 from '../../assets/sliders/slider2.jpg';
// import slider3 from '../../assets/sliders/slider3.jpg';

const Carousel = () => {
  return (
    <div>
      <CCarousel controls transition="crossfade" interval={3000}>
        <CCarouselItem>
          <CImage
            className="carousel-img"
            src={'https://www.duna.ro/uploads/sliders/9fmzkrTwdaJ1NyBTEiMJ_1462738694.jpeg'}
            alt="slide 1"
          />
          <CCarouselCaption className="carousel-caption-custom">
            <h5>
              Garantăm cel mai scurt termen <br /> de execuţie si livrare din România.
            </h5>
            <p>
              Prin specialistii nostri si tehnologia de ultima ora, avem posibilitatea de a onora in cel mai scurt timp
              comenzile dumneavoastra.
            </p>
          </CCarouselCaption>
        </CCarouselItem>
        <CCarouselItem>
          <CImage
            className="carousel-img"
            src={'https://www.duna.ro/uploads/sliders/QCbNNFCRaxkOoWs1WjZV_1462862443.jpeg'}
            alt="slide 2"
          />
          <CCarouselCaption className="carousel-caption-custom">
            <h5>Abrazive profesionale de calitate.</h5>
            <p>
              Parteneriatul nostru cu VSM Germania, este garanţia calităţii profesionale a produselor abrazive oferite
              de compania noastră.
            </p>
          </CCarouselCaption>
        </CCarouselItem>
        <CCarouselItem>
          <CImage
            className="carousel-img"
            src={'https://www.duna.ro/uploads/sliders/yM8FvOrdErh8Agt7ql8K_1462863110.jpeg'}
            alt="slide 3"
          />
          <CCarouselCaption className="carousel-caption-custom">
            <h5>
              Produse pentru performanţe <br /> constante şi echilibrate.
            </h5>
            <p>
              Produsele VSM, de o calitate excelentă, asigură perfomanţe de prelucrare şi anduranţă superioara, oferind
              astfel soluţii de şlefuire economice şi suprafeţe prelucrate la standarde ridicate.
            </p>
          </CCarouselCaption>
        </CCarouselItem>
      </CCarousel>
    </div>
  );
};

export default Carousel;
