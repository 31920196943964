import React, { useState } from 'react';
import { Button, TextField, Container, Grid, Grow } from '@mui/material';
import Alert from '@mui/material/Alert';

interface ContactFormProps {
  onSubmit: (formData: ContactFormData) => void;
}

export interface ContactFormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<ContactFormData>({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const initialFormData: ContactFormData = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  const [submissionAlert, setSubmissionAlert] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState('');
  const emailValidationRegex = /^[^\s@]+@[a-z]+\.[a-z]{2,3}/;

  // const resetForm = () => {
  //   setFormData(initialFormData);
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Validate email if the input name is "email"
    if (name === 'email') {
      if (!emailValidationRegex.test(value)) {
        setEmailError('Formatul email-ului este invalid');
      } else {
        setEmailError('');
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('/api/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmissionAlert(true);
        setFormData(initialFormData);
      } else {
        console.error('Failed to send email => ' + response);
      }
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setIsSubmitting(false);
      onSubmit(formData);
      setTimeout(() => {
        setSubmissionAlert(false);
      }, 2500);
    }
  };

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField required fullWidth name="name" label="Nume" value={formData.name} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!emailError}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              name="phone"
              label="Telefon"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="message"
              label="Mesaj"
              multiline
              minRows={4}
              value={formData.message}
              onChange={handleInputChange}
            />
          </Grid>
          <p style={{ color: 'grey', fontSize: '10px', paddingLeft: '18px' }}>
            Toate campurile marcate cu * sunt obligatorii
          </p>
          <Grid item xs={12}>
            <Button type="submit" variant="outlined" color="primary" disabled={isSubmitting} sx={{ fontWeight: 500 }}>
              {isSubmitting ? 'Trimitem mesajul dvs...' : 'Trimite'}
            </Button>
          </Grid>
        </Grid>
      </form>
      <br />
      <Grow in={submissionAlert}>
        <Alert severity="success" onClose={() => setSubmissionAlert(false)}>
          Mesajul a fost trimis. Va vom contacta in cel mai scurt timp. Va multumim!
        </Alert>
      </Grow>
    </Container>
  );
};

export default ContactForm;
