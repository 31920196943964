import {
  CCollapse,
  CContainer,
  CDropdown,
  //   CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavItem,
  CNavLink,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
} from '@coreui/react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import VerticalDivider from '../VerticalDivider';
import { BrowserView } from 'react-device-detect';

const AppBar = () => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  return (
    <>
      <CNavbar expand="xl" style={{ backgroundColor: '#EEF4ED', padding: 0 }}>
        <CContainer sm>
          <CNavbarBrand href="/">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" className="logo" />
          </CNavbarBrand>
          <CNavbarToggler aria-label="Toggle navigation" aria-expanded={visible} onClick={() => setVisible(!visible)} />
          <CCollapse className="navbar-collapse" visible={visible}>
            <CNavbarNav>
              <CNavItem>
                <CNavLink href="/" className="nav-link" active={location.pathname === '/'}>
                  ACASA
                </CNavLink>
              </CNavItem>
              <BrowserView>
                <VerticalDivider />
              </BrowserView>
              <CDropdown variant="nav-item" popper={false}>
                <CDropdownToggle active={location.pathname === '/high-tech' || location.pathname === '/high-quality'}>
                  PRODUSE
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem className="nav-link" href="/high-tech" active={location.pathname === '/high-tech'}>
                    GAMA HIGH TECH
                  </CDropdownItem>
                  <CDropdownItem
                    className="nav-link"
                    href="/high-quality"
                    active={location.pathname === '/high-quality'}
                  >
                    GAMA HIGH QUALITY
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
              <BrowserView>
                <VerticalDivider />
              </BrowserView>
              <CNavItem>
                <CNavLink
                  className="nav-link"
                  href="/domenii-utilizare"
                  active={location.pathname === '/domenii-utilizare'}
                >
                  DOMENII UTILIZARE
                </CNavLink>
              </CNavItem>
              <BrowserView>
                <VerticalDivider />
              </BrowserView>
              <CNavItem>
                <CNavLink className="nav-link" href="/contact" active={location.pathname === '/contact'}>
                  CONTACT
                </CNavLink>
              </CNavItem>
            </CNavbarNav>
          </CCollapse>
        </CContainer>
      </CNavbar>
    </>
  );
};

export default AppBar;
