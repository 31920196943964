import * as React from 'react';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import pieseTurnate from '../assets/pieseTurnate.png';
import turbine from '../assets/turbine.jpeg';
import lacatuserie from '../assets/lacatuserie.jpeg';
import auto from '../assets/auto.webp';
import sculeUnelte from '../assets/sculeUnelte.jpeg';
import wood from '../assets/wood.jpeg';

// import construction from '../assets/under-constr.jpeg';

const Usage = () => {
  return (
    <div className="container">
      <h1 className="titles">Domenii de utilizare</h1>
      <br />
      <div className="card-container">
        <Card className="card" sx={{ maxWidth: 345 }}>
          <CardMedia sx={{ height: 200 }} image={turbine} title="Turbine" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" align="center">
              Turbine
            </Typography>
          </CardContent>
        </Card>
        &nbsp;&nbsp;
        <Card className="card" sx={{ maxWidth: 345 }}>
          <CardMedia sx={{ height: 200 }} image={lacatuserie} title="Lacatuserie" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" align="center">
              Lacatuserie
            </Typography>
          </CardContent>
        </Card>
        &nbsp;&nbsp;
        <Card className="card" sx={{ maxWidth: 345 }}>
          <CardMedia sx={{ height: 200 }} image={pieseTurnate} title="pieseTurnate" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" align="center">
              Piese turnate
            </Typography>
          </CardContent>
        </Card>
        &nbsp;&nbsp;
        <Card className="card" sx={{ maxWidth: 345 }}>
          <CardMedia sx={{ height: 200 }} image={auto} title="pieseTurnate" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" align="center">
              Constructii masini
            </Typography>
          </CardContent>
        </Card>
        &nbsp;&nbsp;
        <Card className="card" sx={{ maxWidth: 345 }}>
          <CardMedia sx={{ height: 200 }} image={sculeUnelte} title="sculeSiUnelte" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" align="center">
              Scule si unelte
            </Typography>
          </CardContent>
        </Card>
        &nbsp;&nbsp;
        <Card className="card" sx={{ maxWidth: 345 }}>
          <CardMedia sx={{ height: 200 }} image={wood} title="industriaLemnului" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" align="center">
              Industria lemnului
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Usage;
