import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEffect } from 'react';
// import termsAndCondtions from '../assets/termsAndConditions.png';

const Cookies = () => {
  useEffect(() => {
    document.title = 'DUNA TRADE | Termeni si conditii';
  }, []);
  return (
    <Container>
      {/* <Typography variant="h4" gutterBottom>
        <img src={termsAndCondtions} alt="TermsAndConditions" className="terms-img" />
      </Typography> */}
      <Typography gutterBottom>
        <p className="terms-subtitle">Acest site utilizeaza cookies</p>
      </Typography>
      <Typography variant="body2" paragraph>
        COOKIES necesare sunt fisiere text, care sunt folosite de site-uri pentru eficientizarea utilizarii si
        imbunatatirea serviciilor oferite. Aceste fisiere le gasiti pe browser-ul dumneavoastra, pe hard disk-ul
        dispozitivului in functie de site-urile pe care le vizitati si fac interactiunea cu acestea mai sigura si mai
        rapida, deoarece isi pot aminti preferintele dumneavoastra. Site-ul nostru foloseste cookies pentru a va
        distinge de ceilalti utilizatori si acest lucru ne ajuta sa va oferim o experienta mai buna atunci cand navigati
        pe site. Datele stocate prin fisierele cookies pe site-ul nostru, nu arata detalii personale cu ajutorul carora
        sa se poata stabili o identitate individuala. Folosim cookies pentru analizarea traficului pe website si pentru
        personalizarea continutului. Site-ul nu poate functiona la parametri normali fara utilizarea cookies, fara
        acestea accesul la zonele securizate ale site-ului sau navigarea in pagina ar fi ingreunata. Prin folosirea
        cookies de statistici, putem analiza informatii despre modul in care folositi site-ul nostru si vom sti ce
        imbunatatiri putem face. Conform legii, putem stoca cookies pe dispozitivul dvs, doar in cazul in care ele sunt
        necesare pentru navigarea pe site. Perioada de expirare a acestor fisiere de tip cookie este de maximum 2 ani.
        Daca nu esti de acord cu folosirea cookies necesare functionarii normale a site-ului, va trebui sa abandonezi
        navigarea pe site-ul nostru. Pentru diferite alte tipuri de cookies, este necesar acordul dumneavoastra. Va
        puteti retrage consimtamantul privind fisierele de tip cookie in orice moment. In acest scop, puteti sa blocati
        sau eliminati fisierele cookie, fie prin intermediul setarilor browser-ului dvs., web sau prin utilizarea unor
        software-uri puse la dispozitie de terti, sau urmand instructiunile specifice pentru categoriile de cookies din
        sectiunile urmatoare. In orice situatie, pot aparea probleme legate de utilizarea anumitor parti ale site-ului
        web, daca dezactivati fisierele de tip cookie. Acordul dumneavoastra pentru folosirea cookies, este valabil
        pentru domeniul: https://duna.ro/
      </Typography>
      <hr className="style" />

      <Typography gutterBottom>
        <p className="terms-subtitle">RECOMANDARI</p>
      </Typography>
      <Typography variant="body2" paragraph>
        Toate informatiile si recomandarile, precum si toate link-urile catre terti disponibile pe acest site sunt
        oferite, cu buna-credinta. Cu toate ca aceste informatii sunt considerate corecte la data vizualizarii, DUNA
        TRADE nu prezinta garantii cu privire la caracterul complet, actualizat sau exactitatea acestor informatii.
      </Typography>
      <Typography variant="body2" paragraph>
        Aceste informatii va sunt furnizate, cu conditia ca dumneavoastra sau oricare alta persoana care primeste aceste
        informatii, sa faca propriile verificari inainte de orice utilizare a lor.
      </Typography>
      <Typography variant="body2" paragraph>
        COOKIES pentru Marketing, sunt folosite pentru a stoca vizitele si cautarile unui utilizator pe web si a-i afisa
        anunturi relevante si antrenante in functie de cautarile recente. Acest tip de cookies sunt utilizate de
        agentiile de publicitate. COOKIES Preferinte, permit site-ului sa aminteasca informatii precum limba dvs.
        preferata sau zona in care va aflati. COOKIES Statistice, ne ajuta sa intelegem cum interactioneaza utilizatorii
        cu site-ul nostru, prin colectarea informatiilor in mod anonim. Website-ul nostru poate include functii de
        comunicare sociala ,cum ar fi link-uri catre Facebook,Twitter, Instagram, Linkedin sau Youtube. Aceste functii
        pot stoca informatii si pot activa un fisier tip cookie pentru o functionare corecta. Website-ul nostru poate
        utiliza link-uri catre alte site-uri pe care nu le controlam, prin urmare va solicitam sa cititi cu atentie
        politicile lor in privinta datelor personale si a politicii de confidentialitate.
      </Typography>
      <Typography variant="body2" paragraph>
        Google Analytics Website-ul nostru utilizeaza Google Analytics, un serviciu pentru analiza web, furnizat de
        Google. Google Analytics utilizeaza fisiere de tip cookie pentru a ajuta un anumit Website sa analizeze modul in
        care utilizati respectivul Website. Informatiile generate de fisierele de tip cookie cu privire la utilizarea de
        catre dvs. a website-ului (inclusiv adresa dvs. IP), vor fi transmise si stocate de Google pe servere care pot
        fi localizate in UE, SEE si/sau Statele Unite. Google va utiliza aceste informatii in scopul evaluarii
        utilizarii de catre dvs. a website-ului, elaborand rapoarte cu privire la activitatea website-ului pentru
        operatorii website-ului si furnizand alte servicii referitoare la activitatea website-ului si la utilizarea
        internetului. Daca aveti intrebari sau nelamuriri cu privire la prelucrarea si stocarea datelor dvs., sau daca
        doriti modificarea sau stergerea acestora, va rugam sa ne contactati la adresa: office@duna.ro Putem actualiza
        Politica Cookie si va vom informa despre modificari prin postarea noii variante pe site-ul nostru la sectiunea
        “Politica Cookie”
      </Typography>
      <Typography variant="body2" paragraph>
        Website-ul este distribuit pe baza “asa cum este” si “atat cat este disponibil” fara nicio reprezentare sau
        promovare facuta si fara garantie de niciun fel expresa sau implicita, incluzand dar fara a se limita la
        garantiile de calitate satisfacatoare, adecvata pentru un anumit scop, neincalcare, compatibilitate, securitate
        si acuratete.
      </Typography>
      <Typography variant="body2" paragraph>
        Va rugam sa consultati periodic aceasta sectiune, pentru a fi la curent cu orice modificare.
      </Typography>

      <hr className="style" />

      <Typography gutterBottom>
        <p className="terms-subtitle">PROTECTIA DATELOR CU CARACTER PERSONAL</p>
      </Typography>

      <Typography variant="body2" paragraph>
        Pentru a accesa, corecta, sterge si limita prelucrarea datelor personale (GDPR), va rugam sa ne contactati la
        adresa de email: office@duna.ro Puteti solicita ca datele dvs. cu caracter personal sa poata sau nu fi utilizate
        sau transmise catre un alt procesor (GDPR) la adresa de email: office@duna.ro In cazul in care considerati ca
        datele dvs. personale nu au fost protejate conform legii, aveti dreptul de a depune o plangere la autoritatea de
        supraveghere (GDPR)
      </Typography>

      <br />
    </Container>
  );
};

export default Cookies;
