import React, { useEffect } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import sell from '../assets/sell.png';
import financial from '../assets/financial.png';
import CallIcon from '@mui/icons-material/Call';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ContactForm, { type ContactFormData } from './ContactForm';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const Contact = () => {
  useEffect(() => {
    document.title = 'Duna Trade | Contact';
  }, []);

  const handleFormSubmit = (formData: ContactFormData) => {};
  return (
    <div className="container">
      <h1 className="titles">Contact</h1>
      <div className="contact-form-cards">
        <div className="contact-card">
          <Card sx={{ boxShadow: 5, backgroundColor: '#849ba1', height: '230px' }}>
            <CardContent>
              <Typography sx={{ fontSize: 17, fontWeight: 700, fontFamily: "'Varta', 'sans-serif'" }}>
                <FactoryOutlinedIcon /> &nbsp; S.C. DUNA TRADE IMP-EXP S.R.L.
              </Typography>{' '}
              <br />
              <Typography>
                <p>
                  <LocationOnOutlinedIcon /> &nbsp; Str. Principală, Nr. 1/B, Ciceu, Jud. Harghita
                </p>
                <p>
                  <SmartphoneIcon /> &nbsp; 0745 531 897
                </p>
                <p>
                  <CallIcon /> &nbsp; 0336 730 125
                </p>
                <p>
                  <AlternateEmailIcon /> &nbsp; office@duna.ro
                </p>
              </Typography>
            </CardContent>
          </Card>
        </div>
        &nbsp;
        <div className="contact-card">
          <Card sx={{ backgroundColor: '#849ba1', height: '230px', fontFamily: "'Varta', 'sans-serif'" }}>
            <CardContent>
              <Typography>
                <img src={sell} className="icon-contact" alt="icon-contact" />
                <b>Departament vanzari și tehnic</b> <br />
                <p className="name">Claudiu ANDREESCU </p>
                <p>
                  <SmartphoneIcon /> &nbsp; 0748 038 392
                </p>
                <p>
                  <AlternateEmailIcon /> &nbsp; claudiu@duna.ro
                </p>
              </Typography>
            </CardContent>
          </Card>
        </div>
        &nbsp;
        <div className="contact-card">
          <Card sx={{ backgroundColor: '#849ba1', height: '230px', fontFamily: "'Varta', 'sans-serif'" }}>
            <CardContent>
              <Typography>
                <img src={financial} className="icon-contact" alt="icon-contact" />
                <b>Departament economic și administrativ</b> <br />
                <p className="name">Aurora NEAGOE</p>
                <p>
                  <SmartphoneIcon /> &nbsp; 0745 109 047
                </p>
                <p>
                  <AlternateEmailIcon /> &nbsp; neagoe.aurora@yahoo.com
                </p>
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="contact-form">
        <ContactForm onSubmit={handleFormSubmit} />
      </div>
    </div>
  );
};

export default Contact;
