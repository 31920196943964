import React, { useEffect } from 'react';
import construction from '../assets/under-constr.jpeg';

const Products = () => {
  useEffect(() => {
    document.title = 'Duna Trade | Produse';
  }, []);

  return (
    <center>
      <img src={construction} alt="underConstruction" />{' '}
    </center>
  );
};

export default Products;
