import './App.css';
import Footer from './components/FooterV2';
import AppBar from './components/appbar/AppBarV2';
import { Router, Route, Switch } from 'react-router-dom';
import Products from './pages/Products';
import HighTech from './pages/HighTech';
import Usage from './pages/Usage';
import Contact from './pages/Contact';
import HighQuality from './pages/HighQuality';
import Home from './pages/Home';
import { createBrowserHistory } from 'history';
import Terms from './pages/Terms';
import Cookies from './pages/Cookies';

function App() {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <AppBar />
      <Switch>
        <Route path="/products">
          <Products />
        </Route>
        <Route exact path="/high-tech">
          <HighTech />
        </Route>
        <Route exact path="/high-quality">
          <HighQuality />
        </Route>
        <Route exact path="/domenii-utilizare">
          <Usage />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/terms-and-conditions">
          <Terms />
        </Route>
        <Route path="/cookies">
          <Cookies />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
