import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import granulatieCeramica from '../assets/granulatieCeramica.png';
import zirconiu from '../assets/zirconiu.png';
import wet from '../assets/wet.png';
import centerless from '../assets/hq-ceramic/centerless.png';
import dynafilesanding from '../assets/hq-ceramic/dynafilesanding.png';
import flatsanding from '../assets/hq-ceramic/flatsanding.png';
import backstand from '../assets/hq-ceramic/backstand.png';
import planetarygrinding from '../assets/hq-ceramic/planetarygrinding.png';
import roboticgrinding from '../assets/hq-ceramic/roboticgrinding.png';
import drumgrinding from '../assets/hq-ceramic/drumgrinding.png';
import parquetsanding from '../assets/hq-ceramic/parquetsanding.png';
import longbeltsanding from '../assets/hq-ceramic/longbeltsanding.png';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';
import ModalOffer from '../components/modal/ModalOffer';

const HighTech = () => {
  useEffect(() => {
    document.title = 'Duna Trade | High Tech';
  }, []);

  return (
    <div className="container">
      <h1 className="titles">Tehnologie High Tech</h1>
      <br />
      <p className="content">
        Gama High Tech reprezintă cea mai înaltă calitate VSM. Materialul abraziv cu autoascuţire oferă performanţe de
        şlefuire superioare şi durabilitate îndelungată, fiind o soluţie economică de prelucrare. Mai mult, prin
        folosirea lor se pot obţine suprafeţe prelucrate de o calitate superioara. Pentru prelucrarea uscată a oţelului
        inoxidabil sau rezistent la temperaturi înalte, recomandăm folosirea produselor noastre Top Size. Datorită
        tehnologie speciale Top Size, performanţele de şlefuire cresc şi scade temperatura pe suprafaţa prelucrată.
      </p>
      <hr className="style" />
      <div className="card-container">
        <Card className="card" sx={{ boxShadow: 5, backgroundColor: '#E9E1DA' }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }}>
              <h5>Produse VSM ® CERAMICS (granule de ceramică)</h5>
              <br />
              <br />
              <CardMedia
                sx={{ height: 400, padding: '1em 1em 0 1em', objectFit: 'contain' }}
                component="img"
                image={granulatieCeramica}
                title="granulatie ceramica"
              />
              <div className="used-for">
                {/* <img src={granulatieCeramica} className="high-tech-img" alt="high-tech-img" /> */}
                <div className="used-for-container">
                  <p>
                    <b>Potrivit pentru:</b>
                  </p>
                  <img src={centerless} className="used-for-icon" alt="usedFor" />
                  <img src={dynafilesanding} className="used-for-icon" alt="usedFor" />
                  <img src={flatsanding} className="used-for-icon" alt="usedFor" />
                  <img src={backstand} className="used-for-icon" alt="usedFor" />
                  <img src={planetarygrinding} className="used-for-icon" alt="usedFor" />
                  <img src={roboticgrinding} className="used-for-icon" alt="usedFor" />
                  <img src={drumgrinding} className="used-for-icon" alt="usedFor" />
                </div>
              </div>
              <br />
              <p className="product-description">
                Gama VSM ® CERAMICS cuprinde produse speciale pentru şlefuiri agresive la rece. Cu aceste produse
                abrazive, puteţi creşte viteza de prelucrare, în timp ce calitatea suprafeţelor creşte şi se
                îndepărtează o cantitate sporită din materialul prelucrat. Produsul este disponibil pe suport rigid si
                flexibil.
              </p>
              <div className="gridContainer">
                <h4>Granulatii</h4>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">36</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">40</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">50</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">60</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">80</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">100</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">120</span>
                </div>
              </div>
            </Typography>
          </CardContent>
          <div className="ask-for-offer">
            <Button variant="outlined">
              <ModalOffer />
            </Button>
          </div>
        </Card>
        <br />
        <Card className="card" sx={{ boxShadow: 5, backgroundColor: '#E9E1DA' }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }}>
              <h5>Produse zirconiu-corindon</h5>
              <br />
              <br />
              <CardMedia
                sx={{ height: 400, padding: '1em 1em 0 1em', objectFit: 'contain' }}
                component="img"
                image={zirconiu}
                title="granulatie ceramica"
              />
              <div className="used-for">
                {/* <img src={zirconiu} className="high-tech-img" alt="high-tech-img" /> */}
                <div className="used-for-container">
                  <p>
                    <b>Potrivit pentru:</b>
                  </p>
                  <img src={centerless} className="used-for-icon" alt="usedFor" />
                  <img src={parquetsanding} className="used-for-icon" alt="usedFor" />
                  <img src={flatsanding} className="used-for-icon" alt="usedFor" />
                  <img src={backstand} className="used-for-icon" alt="usedFor" />
                  <img src={longbeltsanding} className="used-for-icon" alt="usedFor" />
                  <img src={planetarygrinding} className="used-for-icon" alt="usedFor" />
                  <img src={roboticgrinding} className="used-for-icon" alt="usedFor" />
                  <img src={drumgrinding} className="used-for-icon" alt="usedFor" />
                </div>
              </div>
              <br />
              <p className="product-description">
                Gama zirconiu-corindon vă permite îndepărtarea unor cantităţi mari de material, datorită randamentului
                de tăiere mare şi a acoperirii active, concomitent cu scădereaa temperaturii de
                prelucrare.Zirconiu-Corindon poate fi utilizat in industria metalelor dar si in industria lemnului.
                Produsul este disponbil pe suport rigid poliester.
              </p>
              <div className="gridContainer">
                <h4>Granulatii</h4>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">24</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">36</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">40</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">50</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">60</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">80</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">100</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">120</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">150</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">180</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">220</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">240</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">320</span>
                </div>
              </div>
            </Typography>
          </CardContent>
          <div className="ask-for-offer">
            <Button variant="outlined">
              <ModalOffer />
            </Button>
          </div>
        </Card>
        <br />
        <Card className="card" sx={{ boxShadow: 5, backgroundColor: '#E9E1DA' }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }}>
              <h5>Produse cu granulaţie compactă</h5>
              <br />
              <br />
              <CardMedia
                sx={{ height: 400, padding: '1em 1em 0 1em', objectFit: 'contain' }}
                component="img"
                image={wet}
                title="granulatie ceramica"
              />

              <div className="used-for">
                {/* <img src={wet} className="high-tech-img" alt="high-tech-img" /> */}
                <div className="used-for-container">
                  <p>
                    <b>Potrivit pentru:</b>
                  </p>
                  <img src={centerless} className="used-for-icon" alt="usedFor" />
                  <img src={flatsanding} className="used-for-icon" alt="usedFor" />
                  <img src={planetarygrinding} className="used-for-icon" alt="usedFor" />
                </div>
              </div>
              <br />
              <p className="product-description">
                Produsele VSM cu granulaţie compactă sunt unelte speciale pentru procese de şlefuire umeda. Pe tot
                timpul utilizării - de câteva ori mai lung decât al materialelor cu granule obişnuite - caracteristica
                cea mai importantă este cantitatea constantă a materialului îndepărtat, drept pentru care se obţine o
                rugozitate constantă a suprafeţelor pieselor prelucrate în serie. Acesta este nivelul cel mai înalt de
                calitate VSM. In comparaţie cu materialele abrazive convenţionale, se reduc timpii morţi în producţie
                iar suprafeţele prelucrate sunt evident uniforme. Produsul este disponibil pe suport rigid poliester.
              </p>
              <div className="gridContainer">
                <h4>Granulatii</h4>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">60</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">80</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">120</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">180</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">240</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">320</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">400</span>
                </div>
                <div className="grain-container-box">
                  <span className="grain-box"></span>
                  <span className="grain-label">600</span>
                </div>
              </div>
            </Typography>
          </CardContent>
          <div className="ask-for-offer">
            <Button variant="outlined">
              <ModalOffer />
            </Button>
          </div>
        </Card>
        <br />
      </div>
    </div>
  );
};

export default HighTech;
