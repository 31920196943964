import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEffect } from 'react';
import termsAndCondtions from '../assets/termsAndConditions.png';

const Terms = () => {
  useEffect(() => {
    document.title = 'DUNA TRADE | Termeni si conditii';
  }, []);
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        <img src={termsAndCondtions} alt="TermsAndConditions" className="terms-img" />
      </Typography>
      <Typography gutterBottom>
        <p className="terms-subtitle">ACCEPTAREA ACESTOR TERMENI</p>
      </Typography>
      <Typography variant="body2" paragraph>
        Accesul Dvs. si utilizarea acestui website, www.duna.ro, se supune acestor Termeni si Conditii. Nu veti folosi
        acest website in scopuri ilegale sau interzise prin Termenii si Conditiile care urmeaza. In caz contrar,{' '}
        <b>DUNA TRADE</b> isi rezerva dreptul sa previna sau sa limiteze accesul Dvs. la website, fara notificare
        prealabila si isi declina orice raspundere, de orica natura, derivand dintr-o astfel de utilizare. Prin
        utilizarea website-ului acceptati termenii si conditiile din aceasta pagina. Daca nu acceptati Termenii si
        Conditiile atunci trebuie sa incetati utilizarea website-ului imediat.
      </Typography>
      <hr className="style" />

      <Typography gutterBottom>
        <p className="terms-subtitle">RECOMANDARI</p>
      </Typography>
      <Typography variant="body2" paragraph>
        Toate informatiile si recomandarile, precum si toate link-urile catre terti disponibile pe acest site sunt
        oferite, cu buna-credinta. Cu toate ca aceste informatii sunt considerate corecte la data vizualizarii, DUNA
        TRADE nu prezinta garantii cu privire la caracterul complet, actualizat sau exactitatea acestor informatii.
      </Typography>
      <Typography variant="body2" paragraph>
        Aceste informatii va sunt furnizate, cu conditia ca dumneavoastra sau oricare alta persoana care primeste aceste
        informatii, sa faca propriile verificari inainte de orice utilizare a lor.
      </Typography>
      <Typography variant="body2" paragraph>
        In nici un caz,{' '}
        <b>
          <b>DUNA TRADE</b>
        </b>
        , nu este responsabila pentru daune de orice natura care rezulta din utilizarea sau increderea bazata pe aceste
        informatii sau orice produs care face referinta la aceste informatii. De asemenea, directorii, reprezentantii
        sau salariatii <b>DUNA TRADE</b> nu pot fi in nici o situatie raspunzatori pentru niciun prejudiciu, de orice
        natura, decurgand din utilizarea site-ului sau a continutului accesibil pe site, sau din copierea, afisarea sau
        utilizarea nelegala a acestuia.
      </Typography>
      <Typography variant="body2" paragraph>
        Aceste informatii nu trebuie sa fie interpretate ca o recomandare de a utiliza orice informatie, produs, proces,
        echipament sau formulare care intra in conflict cu orice brevet, drept de autor, sau marca, iar{' '}
        <b>DUNA TRADE</b> nu face nici o declaratie si nu ofera nici un fel garantie, expresa sau implicita, ca orice
        utilizare a acestor informatii nu vor incalca orice brevet, drept de autor sau marca inregistrata.
      </Typography>
      <Typography variant="body2" paragraph>
        Website-ul este distribuit pe baza “asa cum este” si “atat cat este disponibil” fara nicio reprezentare sau
        promovare facuta si fara garantie de niciun fel expresa sau implicita, incluzand dar fara a se limita la
        garantiile de calitate satisfacatoare, adecvata pentru un anumit scop, neincalcare, compatibilitate, securitate
        si acuratete.
      </Typography>
      <Typography variant="body2" paragraph>
        In limita impusa de lege, <b>DUNA TRADE</b>, nu va fi trasa la raspundere pentru pierderi directe sau indirecte,
        pentru pierderi de orice fel (incluzand dar nelimitandu-se la pierderi de afaceri, de oportunitati, de date, de
        profituri), decurgand din sau in legatura cu folosirea website-ului.
      </Typography>
      <Typography variant="body2" paragraph>
        <b>DUNA TRADE</b> nu ofera nici o garantie ca functionarea website-ului va fi fara intrerupere sau fara erori,
        ca defectele vor fi corectate sau ca website-ul sau serverul care il face disponibil sunt lipsite de virusi sau
        orice altceva ce poate fi daunator sau distructiv.
      </Typography>
      <Typography variant="body2" paragraph>
        Nicio alta companie nu are dreptul de a face sub nicio forma in numele <b>DUNA TRADE</b> , actualizarea sau
        corectarea informatiilor distribuite pe Internet sau pe servere Web.
      </Typography>
      <Typography variant="body2" paragraph>
        De asemenea, <b>DUNA TRADE</b> isi rezerva dreptul de a distribui sau corecta continutul siteului in orice
        moment, fara notificare.
      </Typography>
      <hr className="style" />

      <Typography gutterBottom>
        <p className="terms-subtitle">PROPRIETATE INTELECTUALA</p>
      </Typography>
      <Typography variant="h6" gutterBottom>
        Copyright ©
      </Typography>
      <Typography variant="body2" paragraph>
        Drepturile de Proprietate Intelectuala asupra acestui website si asupra materialelor din acesta sau accesibile
        prin acesta apartin <b>DUNA TRADE</b>. Website-ul, materialele de pe website sau cele accesibile prin acesta si
        drepturile de proprietate intelectuala inerente nu pot fi copiate, distribuite, publicate, licentiate, folosite
        sau reproduse in niciun fel fara permisiunea scrisa explicita din partea <b>DUNA TRADE</b> .
      </Typography>
      <Typography variant="body2" paragraph>
        Drepturile de proprietate intelectuala includ urmatoarele: orice drept, marca inregistrata, nume inregistrat,
        design, drept asupra design-ului, copyright, drept asupra bazelor de date, drepturi morale, know how, informatii
        confidentiale, drepturi de natura oricaror dintre aceste elemente in orice tara, drepturi de natura competitiei
        neloiale si drepturi de a da in judecata pentru transmitere sau alte drepturi similare intelectuale si
        comerciale, inregistrarile si aplicatiile de inregistrare pentru oricare dintre ele.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Informatii Personale
      </Typography>
      <Typography variant="body2" paragraph>
        Orice date cu caracter personal care sunt primite pe acest site sunt destinate exclusiv comunicarii, astfel
        incat sa puteti beneficia de serviciile oferite aici. Nici una din informatiile cu caracter personal nu vor fi
        comunicate partilor terte sau folosite, in scopuri neautorizate.
      </Typography>
      <hr className="style" />

      <Typography variant="h5" gutterBottom>
        <p className="terms-subtitle">ANGAJAMENTUL UTILIZATORULUI</p>
      </Typography>
      <Typography variant="body2" paragraph>
        Fiecare vizitator al site-ului <b>DUNA TRADE</b>, care furnizeaza informatii catre <b>DUNA TRADE</b>, cedeaza
        catre aceasta drepturile de transfer complet de informatii si autorizeaza utilizarea acestora asa cum considera
        de cuviinta. Informatiile furnizate de vizitatori vor fi considerate ca neconfidentiale si trebuie sa fie
        exacte, legale si non-negative. Vizitatorii sunt considerati ca au luat act de avertizarea de mai sus si ca vor
        tine cont de fiecare dintre aceste recomandari. <b>DUNA TRADE</b> asigura confidentialitatea datelor cu caracter
        personal prelucrate si asigura ca acestea sunt stocate strict pe durata necesara realizarii scopurilor in care
        datele sunt colectate.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Linkuri catre website-urile tertilor
      </Typography>
      <Typography variant="body2" paragraph>
        Website-ul poate include linkuri catre website-urile tertilor, care sunt controlate si conduse de altii. Orice
        link spre un alt website este oferit cu scop strict informativ si nu este o recomandare de accesare a acelui
        website, iar utilizatorul ia la cunostinta acest fapt si este constient de faptul ca nu ne asumam
        responsabilitatea pentru continutul ,disponibilitatea acestor website-uri sau orice dauna care va rezulta din
        utilizarea website-urilor in cauza.
      </Typography>
      <br />
    </Container>
  );
};

export default Terms;
