import React, { useEffect } from 'react';
import '../App.css';
// import pic1 from '../assets/pic1.jpg';
// import pic4 from '../assets/pic4.webp';
// import RoProducts from '../components/RoProducts';
import Carousel from '../components/carousel/Carousel';
import Usage from './Usage';
import YoutubeEmbed from '../components/YoutubeEmbed';

const Home = () => {
  useEffect(() => {
    document.title = 'Duna Trade | Home';
  }, []);

  return (
    <div className="container">
      <div className="head-text">
        <div className="image-container">
          {/* <img src={pic4} className="imgHome" alt="homeImg" />
          <div className="text-on-image">
            <h1 className="title">DUNA TRADE</h1>
          </div> */}
          <Carousel />
        </div>
      </div>
      {/* TODO will be moved to another page */}
      {/* <div className="ro-prod">
        <RoProducts />
      </div> */}
      <hr className="style" />
      <p className="subtitle">Despre noi</p>
      <p className="content">
        Înființată în anul 2002, compania <b> DUNA TRADE IMP-EXP SRL </b> a devenit unul dintre principalii jucători ce
        activează pe piață abrazivelor pe suport din România. Activitatea desfășurată încă de la înființare este de
        conversie a abrazivelor. Fiind adepții furnizării unor produse și servicii de calitate, ne-am orientat către un
        producător de materie prima care ne poate asigura realizarea acestui obiectiv, și anume <b>VSM Germania.</b>{' '}
        <br />
      </p>
      <p className="content">
        Asigurăm asistență de specialitate promptă partenerilor noștri atât în recomandarea produsului cel mai potrivit
        activității desfășurate de aceștia cât și pentru preîntâmpinarea și/sau soluționarea problemelor. Experiență
        acumulată ne ajută în acoperirea unui segment cât mai mare din piața producătorilor de mobilă, parchet, sticlă
        și industria de prelucrare a metalelor. <br />
      </p>
      <hr className="style" />
      <YoutubeEmbed embedId="pDmnyNdt664" />
      <hr className="style" />

      <Usage />
    </div>
  );
};

export default Home;
